import React, { useRef } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import BarcodeScanner from "./BarcodeScanner"
import Input from "app/components/common/Input";
import Panel from "app/components/common/Panel";
import Form from "app/components/common/Form";
import InputField from "app/components/commonUI/InputField";
import Api from "app/services/api";
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export default function RegisterInputModal({ openDialog, onCodeDetected, handleOnNextCloseClicked, handleOnSendClicked }) {
  const inputRef = useRef(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [code, setCode] = React.useState(false);

  var inspectionKitId = "";

  React.useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  const onCloseClicked = () => {
    handleClose();
    handleOnNextCloseClicked();
  }

  const handleCodeDetected = (code) => {
    // 在这里接收从 BarcodeScanner 组件传来的条形码信息
    handleClose();
    setCode(code)
    onCodeDetected(code);
  };

  async function getScalpData(examinationDataId) {
    const response = await Api.examinationDatas.get(examinationDataId);
  }

  const onSendClicked = (e) => {
    e.preventDefault();
    console.log("Ricky onSendClickedonSendClickedonSendClickedonSendClicked")

    if(inspectionKitId === "") return;

    onCodeDetected(inspectionKitId);
  }


  document.addEventListener(
    "keydown",
    (event) => {
      const keyName = event.key;
  
      if (keyName === "Control") {
        // do not alert when only Control key is pressed.
        return;
      }

      // Chen : Detect if current url is /scalp-lab-management/batch-receiveDrc-detail/{id}/detail
      const url = window.location.href;
      const pattern = /https?:\/\/[^/]+\/scalp-lab-management\/batch-receiveDrc-detail\/[^/]+\/detail/;
      if (pattern.test(url)) {
        console.log('Keydown event listener');
      } else {
        return;
      }

  
      if (event.ctrlKey) {
        // Even though event.key is not 'Control' (e.g., 'a' is pressed),
        // event.ctrlKey may be true if Ctrl key is pressed at the same time.
        console.log(`Combination of ctrlKey + ${keyName}`);
      } else {
        console.log(`Key pressed ${keyName}`);

        console.log("RRRRRRRRRRRR open", open)
        if(open){
          inputRef?.current?.focus()
        }else {
        }
      }
    },
    false,
  );

  return (
    <div>
      <Modal
        open={open}
        onClose={onCloseClicked}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={onSendClicked} className="input">
            <div className="flex flex-col md:flex-row gap-0 md:gap-6 items-center justify-center">
              <div className="w-full md:w-2/3 justify-end flex items-end  ">
                <div className="w-full h-auto pr-3 mb-5 flex items-center justify-center">
                  <InputField
                    label="検体No."
                    name="inspectionKitId"
                    // value={1}
                    type="text"
                    onChange={(newValue) => {
                      console.log("-----")
                      inspectionKitId = newValue;
                    }}
                    // handleBlur={handleBlur}
                    // touched={touched.customerNo}
                    // errors={errors.customerNo}
                    labelClasses="!w-24"
                    ref={inputRef}
                    inputProps={
                      {
                        ref:inputRef,
                        style: { imeMode: 'inactive' } // 設置 style 物件
                        //autoFocus:true,
                      }
                    }
                  />
                </div>
              </div>
              <div className="w-full justify-end flex items-end md:w-auto md:items-baseline">
                <button
                  onClick={onSendClicked}
                  // type="button"
                  type="submit"
                  className={`w-auto h-8 flex items-center justify-center gap-2 bg-[#666FC8] px-4 rounded text-white`}
                  disabled={
                    false
                  }
                >
                  登録
                </button>
              </div>
            </div>
          </form>

          

          {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography> */}
          <div>
            {code}
          </div>
        </Box>
      </Modal>
    </div>
  );
}