import React from "react";
import Label from "app/components/common/Label";
import Button from "app/components/common/Button";

function KitRequestHistoryTableItem(props) {
  const { children, onDeliverButtonClick, onConfirmButtonClick } = props;
  const kitRequestHistoryItem = children;

  const onDeliverButtonClicked = () => {
    onDeliverButtonClick(kitRequestHistoryItem);
  };

  const onConfirmButtonClicked = () => {
    onConfirmButtonClick(kitRequestHistoryItem);
  };

  return (
    <tr className="border-b dark:border-neutral-500">
      <td className="whitespace-nowrap px-6 py-4 font-medium">
        <Label.DateTime dateFormat="YYYY-MM-DD">
          {kitRequestHistoryItem.orderDate}
        </Label.DateTime>
      </td>
      <td className="whitespace-nowrap px-6 py-4">
        {kitRequestHistoryItem.salon.id}
      </td>
      <td className="whitespace-nowrap px-6 py-4">
        {kitRequestHistoryItem.salon.name}
      </td>
      <td className="whitespace-nowrap px-6 py-4">
        {kitRequestHistoryItem.amount}
      </td>
      <td className="whitespace-nowrap px-6 py-4">
        {
          kitRequestHistoryItem.inspectionKitSalonOrder?.orderStatus === "waiting" ? 
            <Button.Primary onClick={onConfirmButtonClicked}>発注確認</Button.Primary> : 
            <Button.Primary onClick={onDeliverButtonClicked}>発送</Button.Primary>
        }
      </td>
    </tr>
  );
}

export default KitRequestHistoryTableItem;
