import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Path from "../../../../route/Path";
import dayjs from "dayjs";

export default function TableScalpDataList(props) {
  const navigate = useNavigate();
  const { allHistoryOrdering } = props;

  return (
    <div className="w-full h-full relative overflow-auto min-w-[900px]">
      {/* header */}
      <div className="sticky top-0 left-0 w-full h-14 flex bg-gray-100 border-b z-10">
        <div className="w-1/6 flex items-center justify-center shrink-0 truncate">
          検体No.
        </div>
        <div className="w-1/6 flex items-center justify-center shrink-0 truncate">
          お客様No.
        </div>
        <div className="w-1/6 flex items-center justify-center shrink-0 truncate">
          お客様名
        </div>
        <div className="w-1/6 flex items-center justify-center shrink-0 truncate">
          登録日時
        </div>
      </div>

      {/* body */}
      {allHistoryOrdering.map((row, index) => (
        <div className="w-full h-14 flex bg-white border-b">
          <div className="w-1/6 flex items-center justify-center">
            {row?.inspectionKitId === ""
              ? "-"
              : row?.inspectionKitId}
          </div>
          <div className="w-1/6 flex items-center justify-center">
            {row?.customerNo}
          </div>
          <div className="w-1/6 flex items-center justify-center">
            {row?.customerName}
          </div>
          <div className="w-1/6 flex items-center justify-center">
            {row.scalpLabReceiveDate !== undefined
              ? dayjs(row.scalpLabReceiveDate).format("YYYY-MM-DD HH:mm")
              : "-"}
          </div>
          {/* <div className="w-1/6 flex items-end justify-center">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                navigate(
                  Path.historyOfOrderingDetailByKitId(
                    row.scalpData?.customerNo,
                    row.scalpData?.inspectionKitId
                  )
                );
              }}
            >
              詳細
            </Button>
          </div> */}
        </div>
      ))}
    </div>
  );
}
