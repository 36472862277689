import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import dayjs from "dayjs";
import Path from "app/route/Path";
import Api from "app/services/api";
import StepsBar from "app/components/common/StepsBar";
import Input from "app/components/common/Input";
import Button from "app/components/common/Button";
import Label from "app/components/common/Label";
import Panel from "app/components/common/Panel";
import Form from "app/components/common/Form";
import SearchBox from "app/components/common/SearchBox";
import Salon from "app/components/Salon";
import BackBtn from "../../BackButton";

function KitDeliveryForm(props) {
  const { isPrefilled, stepId, children } = props;
  const kitRequestItem = children;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchSalons, setSearchSalons] = useState([]);
  const [searchSalonsPending, setSearchSalonsPending] = useState(true);
  const [searchSalonsLoaded, setSearchSalonsLoaded] = useState(false);

  const steps = [
    { name: "発送登録" },
    { name: "入力確認" },
    { name: "登録完了" },
  ];

  const navigate = useNavigate();

  const {
    handleSubmit,
    // handleChange,
    // errors,
    values,
    setFieldValue,
    // resetForm,
    // touched,
    // handleBlur,
  } = useFormik({
    initialValues: {
      deliveryDate: dayjs(),
      amount: isPrefilled ? kitRequestItem.amount : 0,
      salonId: isPrefilled ? kitRequestItem.salonId : "",
      selectedSalonItem: isPrefilled ? kitRequestItem.salon : {},
      // memo: "",
      memo: isPrefilled ? kitRequestItem.memo : "",
      inspectionKitSalonOrderId: kitRequestItem.inspectionKitSalonOrderId,
    },
    onSubmit: async (values) => {
      setIsSubmitting(true);
      const response = await (isPrefilled ? Api.kit.delivery.edit(kitRequestItem.id, values) : Api.kit.delivery.create(values));
      setIsSubmitting(false);
      gotoNextStep();
      // alert(JSON.stringify(values, null, 2));
      // setTimeout(() => {
      //   gotoNextStep();
      //   setIsSubmitting(false);
      // }, 1000);
    },
  });

  const gotoPrevStep = () => {
    isPrefilled
      ? navigate(Path.kit.delivery.fulfill(kitRequestItem.id, stepId - 1), {
          state: { kitRequestItem: kitRequestItem },
        })
      : navigate(Path.kit.delivery.create(stepId - 1));
  };

  const gotoNextStep = () => {
    isPrefilled
      ? navigate(Path.kit.delivery.fulfill(kitRequestItem.id, stepId + 1), {
          state: { kitRequestItem: kitRequestItem },
        })
      : navigate(Path.kit.delivery.create(stepId + 1));
  };

  const step1IsValid = () => {
    if (!values.deliveryDate) {
      return false;
    }
    if (!values.salonId) {
      return false;
    }
    if (values.amount <= 0) {
      return false;
    }
    if (!values.memo) {
      return false;
    }

    return true;
  };

  const kitDeliveryFinished = () => {
    navigate(Path.kit.delivery.list);
  };

  const searchClicked = async (evt) => {
    if (searchText === "") {
      return;
    }

    setSearchSalonsPending(false);
    setSearchSalonsLoaded(false);
    const response = await Api.salons.search(searchText);
    const items = response.data;
    setSearchSalons(items);
    setSearchSalonsLoaded(true);
  };

  const salonItemClicked = (salonItem) => {
    setFieldValue("salonId", salonItem.id);
    setFieldValue("selectedSalonItem", salonItem);
  };

  const onRemoveClicked = () => {
    setFieldValue("salonId", "");
    setFieldValue("selectedSalonItem", {});
  };

  const renderByStepIndex = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <Form.Row>
              <Form.Col>配送日</Form.Col>
              <Form.ColGrow>
                <Input.DatePicker
                  disablePast
                  name="deliveryDate"
                  className="w-full"
                  value={values.deliveryDate}
                  setValue={(newValue) => {
                    setFieldValue("deliveryDate", newValue);
                  }}
                ></Input.DatePicker>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>数量</Form.Col>
              <Form.ColGrow>
                <Input.Field
                  name="amount"
                  type="number"
                  className="w-full"
                  value={values.amount}
                  setValue={(newValue) => {
                    setFieldValue("amount", newValue);
                  }}
                ></Input.Field>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>配送店舗</Form.Col>
              <Form.ColGrow>
                {isPrefilled ? (
                  <Salon.Grid.Item>{kitRequestItem.salon}</Salon.Grid.Item>
                ) : (
                  <>
                    {values.salonId ? (
                      <>
                        <Salon.Grid.Item>
                          {values.selectedSalonItem}
                        </Salon.Grid.Item>
                        <Button.Danger onClick={onRemoveClicked}>
                          別の美容室を選択
                        </Button.Danger>
                      </>
                    ) : (
                      <>
                        <SearchBox
                          placeholder="美容室を探す"
                          value={searchText}
                          setValue={setSearchText}
                          onSearch={searchClicked}
                        />
                        <Salon.Grid.Grid
                          items={searchSalons}
                          loading={!searchSalonsLoaded}
                          pending={searchSalonsPending}
                        >
                          <Salon.Grid.Pending>
                            検索ボタンを押してください。
                          </Salon.Grid.Pending>
                          <Salon.Grid.Loading>検索中</Salon.Grid.Loading>
                          <Salon.Grid.Empty>
                            該当美容室がないです。
                          </Salon.Grid.Empty>
                          <Salon.Grid.Items>
                            {searchSalons.map((item) => (
                              <Salon.Grid.Item
                                key={item.id}
                                onClicked={salonItemClicked}
                                selected={item.id === values.salonId}
                              >
                                {item}
                              </Salon.Grid.Item>
                            ))}
                          </Salon.Grid.Items>
                        </Salon.Grid.Grid>
                      </>
                    )}
                  </>
                )}
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>備考</Form.Col>
              <Form.ColGrow>
                <Input.MultilineField
                  name="memo"
                  className="w-full"
                  value={values.memo}
                  setValue={(newValue) => {
                    setFieldValue("memo", newValue);
                  }}
                ></Input.MultilineField>
              </Form.ColGrow>
            </Form.Row>
            <Panel.Justify className="!sticky !bottom-0 w-full bg-white p-2">
              <BackBtn url={Path.kit.home}/>
              <Button.Primary onClick={gotoNextStep} disabled={!step1IsValid()}>
                次へ
              </Button.Primary>
            </Panel.Justify>
          </>
        );
      case 1:
        return (
          <div className="mx-2 space-y-4">
            <Form.Row>
              <Form.Col>配送日</Form.Col>
              <Form.ColGrow>
                <Label.DateTime dateFormat="YYYY-MM-DD">
                  {values.deliveryDate}
                </Label.DateTime>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>数量</Form.Col>
              <Form.ColGrow>{values.amount}</Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>配送店舗</Form.Col>
              <Form.ColGrow>
                {isPrefilled ? (
                  <Salon.Grid.Item>{kitRequestItem.salon}</Salon.Grid.Item>
                ) : (
                  <Salon.Grid.Item>{values.selectedSalonItem}</Salon.Grid.Item>
                )}
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>備考</Form.Col>
              <Form.ColGrow>
                <p className="whitespace-pre-wrap">{values.memo}</p>
              </Form.ColGrow>
            </Form.Row>
            <Panel.Justify className="!sticky !bottom-0 w-full bg-white p-2">
              <Button onClick={gotoPrevStep}>戻る</Button>
              <Button.Primary type="submit" loading={isSubmitting}>
                発送情報を登録します
              </Button.Primary>
            </Panel.Justify>
          </div>
        );

      case 2:
        return (
          <div className="text-center space-y-4 mx-2">
            <Form.Row>
              <Form.ColGrow>発送情報を登録しました</Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.ColGrow>
                <Button.Primary onClick={kitDeliveryFinished}>
                  完了
                </Button.Primary>
              </Form.ColGrow>
            </Form.Row>
          </div>
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      <StepsBar steps={steps} currentStepIndex={stepId} />
      <Form.Form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
        {renderByStepIndex(stepId)}
      </Form.Form>
    </>
  );
}

export default KitDeliveryForm;
