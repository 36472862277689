import React from "react";
import DefaultLayout from "app/layouts/DefaultLayout";
import Kit from "app/components/Kit";
import MainMenuItem from "app/components/MainMenuItem";
import BackBtn from "../../components/BackButton";
import Path from "app/route/Path";


function ProductHomePage() {
  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        // pageTitle: "ホーム",
      }}
    >
      <Kit.MenuGrid>
        {/* <div className="flex items-center justify-center aspect-square w-full h-full p-[10%]">
          {<MainMenuItem.Kit.Order.Create />}
        </div> */}
        {/* <div className="flex items-center justify-center aspect-square w-full h-full p-[10%]">
          {<MainMenuItem.Kit.Stock.Create />}
        </div> */}
        <div className="flex items-center justify-center aspect-square w-full h-full p-[10%]">
          {<MainMenuItem.Product.Order.List />}
        </div>
        {/* <div className="flex items-center justify-center aspect-square w-full h-full p-[10%]">
          {<MainMenuItem.Kit.Order.History />}
        </div> */}
        {/* <div className="flex items-center justify-center aspect-square w-full h-full p-[10%]">
          {<MainMenuItem.Kit.Stock.List />}
        </div> */}
        <div className="flex items-center justify-center aspect-square w-full h-full p-[10%]">
          {<MainMenuItem.Product.Order.DeliveryHistory />}
        </div>
      </Kit.MenuGrid>
      <BackBtn url={Path.home}/>
    </DefaultLayout>
  );
}

export default ProductHomePage;
