import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Path from "app/route/Path";
import Api from "app/services/api";
import Button from "app/components/common/Button";
import Label from "app/components/common/Label";
import Layout from "app/components/common/Layout";
import Modal from "app/components/common/Modal";
import Input from "app/components/common/Input";

function ExaminationResultDetail(props) {
  const { children } = props;
  const examinationResultItem = children;

  const navigate = useNavigate();

  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [showDeleteReconfirmModal, setShowDeleteReconfirmModal] =
    useState(false);
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const showDeleteConfirmationModalClicked = (evt) => {
    setShowDeleteConfirmationModal(true);
  };

  const confirmDeleteClicked = (evt) => {
    setShowDeleteConfirmationModal(false);
    setShowDeleteReconfirmModal(true);
  };

  const reconfirmDeleteClicked = async (evt) => {
    setIsSubmitting(true);
    const response = await Api.examinationDatas.delete(
      examinationResultItem.id
    );
    if (response.status === 200) {
      setShowDeleteReconfirmModal(false);
      setShowCompleteModal(true);
      setIsSubmitting(false);
    } else {
      // TODO: Error handling
      setIsSubmitting(false);
      alert("Error");
    }
  };

  const closeCompleteModalClicked = (evt) => {
    setShowCompleteModal(false);
    navigate(Path.drc.examinationResult.list);
  };

  const editClicked = () => {
    navigate(Path.drc.examinationResult.edit(examinationResultItem.id, 0));
  };

  console.log(examinationResultItem);

  return (
    <>
      <Modal
        show={showDeleteConfirmationModal}
        setShow={setShowDeleteConfirmationModal}
        staticBackdrop={true}
        title={"このを削除しますか？"}
        button1={
          <Button.Danger onClick={(evt) => confirmDeleteClicked(evt)}>
            削除を確認する
          </Button.Danger>
        }
        button2={
          <Button onClick={() => setShowDeleteConfirmationModal(false)}>
            閉じる
          </Button>
        }
      ></Modal>

      <Modal
        show={showDeleteReconfirmModal}
        setShow={setShowDeleteReconfirmModal}
        staticBackdrop={true}
        title={"（最終確認）このを削除しますか？"}
        button1={
          <Button.Danger
            onClick={(evt) => reconfirmDeleteClicked(evt)}
            loading={isSubmitting}
          >
            削除を確認する
          </Button.Danger>
        }
        button2={
          <Button onClick={() => setShowDeleteReconfirmModal(false)}>
            閉じる
          </Button>
        }
      ></Modal>

      <Modal
        show={showCompleteModal}
        setShow={setShowCompleteModal}
        staticBackdrop={true}
        title={"このを削除しました。"}
        button1={
          <Button.Secondary onClick={(evt) => closeCompleteModalClicked(evt)}>
            終了
          </Button.Secondary>
        }
      ></Modal>

      <Layout.Container>
        <Layout.Row>
          <Layout.Col grow={true}>
            <p>お客様 ID: {examinationResultItem.cusId}</p>
            <p>キット ID: {examinationResultItem.kitId}</p>
            <p>クラスター: {examinationResultItem.cluster}</p>
            <p>細胞面積: {examinationResultItem.cellArea}</p>
            <p>多重剥離: {examinationResultItem.peeling}</p>
            <p>CP: {examinationResultItem.cp}</p>
            <p>総合: {examinationResultItem.overall}</p>
            <p className="font-bold">
              分析状況: {examinationResultItem.analyseStatus}
            </p>
            <p>グラフ未実装</p>
          </Layout.Col>
        </Layout.Row>

        <Layout.Row single={true}>
          <Layout.Col>
            <Button.Secondary onClick={editClicked}>
              この検査データを編集
            </Button.Secondary>
          </Layout.Col>
        </Layout.Row>
        <Layout.Row single={true}>
          <Layout.Col>
            <Button.Danger onClick={showDeleteConfirmationModalClicked}>
              削除
            </Button.Danger>
          </Layout.Col>
        </Layout.Row>
      </Layout.Container>
    </>
  );
}

export default ExaminationResultDetail;
