import homePage from "./homePage";
import salesOrderManagementListPage from "./SalesOrderManagement/listPage";
import batchReceiveDrcPage from "./SalesOrderManagement/batchReceiveDrcPage";
import batchReceiveDrcDetailPage from "./SalesOrderManagement/BatchReceiveDrcDetailPage";
import examinationResultCreatePage from "./ExaminationResult/createPage";
import examinationResultListPage from "./ExaminationResult/listPage";
import examinationResultDetailPage from "./ExaminationResult/detailPage";
import examinationResultEditPage from "./ExaminationResult/editPage";
import examinationResultUploadPage from "./ExaminationResult/uploadPage";
import labContactCreatePage from "./LabContact/createPage";

const exportObjs = {
  Home: homePage,
  SalesOrderManagement: {
    List: salesOrderManagementListPage,
    BatchReceiveDrc: batchReceiveDrcPage,
    BatchReceiveDrcDetail: batchReceiveDrcDetailPage
  },
  ExaminationResult: {
    List: examinationResultListPage,
    Create: examinationResultCreatePage,
    Detail: examinationResultDetailPage,
    Edit: examinationResultEditPage,
    Upload: examinationResultUploadPage,
  },
  LabContact: {
    Create: labContactCreatePage,
  },
};

export default exportObjs;
