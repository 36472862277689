import KitMenuGrid from "./KitMenuGrid";
//import KitOrderHistoryTableItem from "./Order/KitOrderHistoryTableItem";
//import KitOrderForm from "./Order/KitOrderForm";
import ProductDeliveryHistoryTableItem from "./Delivery/ProductDeliveryHistoryTableItem";
import ProductDeliveryForm from "./Delivery/ProductDeliveryForm";
import ProductOrderConfirmTableItem from "./Order/ProductOrderConfirmTableItem";
//import KitStockHistoryTableItem from "./Stock/KitStockHistoryTableItem";
//import KitStockForm from "./Stock/KitStockForm";
import Table from "app/components/common/Table";

const exportObjs = {
  // MenuGrid: KitMenuGrid,
  // Order: {
  //   Table: {
  //     ...Table,
  //     Item: KitOrderHistoryTableItem,
  //   },
  //   Form: KitOrderForm,
  // },
  Delivery: {
    Table: {
      ...Table,
      HistoryItem: ProductDeliveryHistoryTableItem,
    },
    Form: ProductDeliveryForm,
  },
  Order: {
    Table: {
      ...Table,
      Item: ProductOrderConfirmTableItem,
    },
  },
  // Stock: {
  //   Table: {
  //     ...Table,
  //     Item: KitStockHistoryTableItem,
  //   },
  //   Form: KitStockForm,
  // },
};

export default exportObjs;
